import { PageProps, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React, { useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'
import styled, { useTheme } from 'styled-components'
import { SEO } from '../SEO'
import { Button } from '../components/Button'
import Container from '../components/Container'
import Footer from '../components/Footer/Footer'
import Form from '../components/Form'
import PDFViewer from '../components/PDFViewer'
import { Site } from '../components/Site'
import { Main } from '../components/Styled'

const DaiseeEBook: React.FC<PageProps> = props => {
  const localStorageKey = 'submitted-e-book-001'
  const [submitted, setSubmitted] = useLocalStorage<boolean>(localStorageKey, false)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const theme = useTheme()

  const { ebook, pdf } = useStaticQuery(graphql`
    query DaiseeEBook {
      ebook: file(relativePath: { eq: "e-book-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        publicURL
      }
      pdf: file(relativePath: { eq: "daisee-e-book.pdf" }) {
        publicURL
      }
    }
  `)

  const CTA = submitted ? 'Get the daisee E-book now' : 'Download the daisee E-book'

  const handleSubmit = () => {
    setTimeout(() => {}, 2000)
    setSubmitted(true)
  }

  useEffect(() => {
    const value = localStorage.getItem(localStorageKey)
    if (value === 'true') {
      setSubmitted(true)
    }
  }, [])

  const Image = <Img fluid={ebook.childImageSharp.fluid} />

  return (
    <Site seo={{ ...SEO.ebook, image: ebook.publicURL }} {...props}>
      <Main>
        <Container>
          <PageContent>
            <div className='grid'>
              <div className='form-wrapper'>
                <h1 className='cta'>{CTA}</h1>
                {!submitted && (
                  <FormWrapper>
                    <Form
                      formName='daisee-ebook'
                      successMessage='Success! Download or view the daisee E-book now'
                      errorMessage='Oops, something went wrong - please try again'
                      callback={handleSubmit}
                      action='Download'
                    />
                  </FormWrapper>
                )}
              </div>
              <div>
                <div className='text'>
                  <h2>Harnesses AI To Solve Contact Centre Problems</h2>
                </div>
                {submitted ? (
                  <HasDownloaded>
                    <div className={`button-wrapper`}>
                      <a className='button' href={pdf.publicURL} download>
                        <Button action='Download' btnHeight='auto' bgColor={theme.colors.green} />
                      </a>
                      <Button
                        action='View Online'
                        btnHeight='auto'
                        bgColor={theme.colors.green}
                        onClick={() => setIsViewerOpen(true)}
                      />
                    </div>
                    {Image}
                  </HasDownloaded>
                ) : (
                  <ImageWrapper>{Image}</ImageWrapper>
                )}
              </div>
            </div>
            {isViewerOpen && <PDFViewer pdfPath={pdf.publicURL} onClose={() => setIsViewerOpen(false)} />}
          </PageContent>
        </Container>
      </Main>
      <Footer />
    </Site>
  )
}

const FormWrapper = styled.div`
  max-width: 786px;
  margin: 0 auto 3rem;
`

const HasDownloaded = styled.span`
  position: relative;
  height: fit-content;
  display: block;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
    transition: opacity 0.4s ease;
  }

  .button-wrapper {
    display: flex;
    position: absolute;
    z-index: 3;
    justify-content: center;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  height: fit-content;

  ${({ theme }) => theme.breakpoints.tablet} {
    margin: 0;
  }
`

const PageContent = styled.div`
  margin: 9rem 0 0;

  h1,
  a {
    color: ${({ theme }) => theme.colors.purple};
  }

  .button-wrapper {
    position: absolute;
    display: grid;
    grid-auto-flow: column;
    gap: 1.5rem;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);

    a {
      text-decoration: none;
    }
  }

  .submitted {
    .button-wrapper {
      opacity: 1;
      display: grid;
    }
    &:before {
      opacity: 1;
    }
  }

  a {
    text-decoration: underline;
  }

  h2,
  h1 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: -0.5px;
  }

  h2 {
    position: relative;
    padding-left: 1rem;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      height: 2rem;
      background: ${({ theme }) => theme.colors.purple};
      width: 4px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    padding-bottom: 3rem;

    ${({ theme }) => theme.breakpoints.tablet} {
      grid-template-columns: 1fr;
    }
  }

  .cta {
    font-weight: 600;
    font-size: 2rem;
    margin: 0 0 2rem;
    color: ${({ theme }) => theme.colors.purple};
  }

  .text {
    display: grid;
    gap: 1.5rem;
    height: fit-content;
    margin-bottom: 2rem;
  }
`

export default DaiseeEBook
